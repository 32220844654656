<template>
  <!-- Version: 1.7 -->
  <h2>1. Oyun Açıklaması</h2>
  <p>
    Highlight Games Soccerbet Oyunu, oynama fırsatları yaratmak için maçlardan rastgele seçilen futbol anlarının
    benzersiz bir yazılım tabanlı simülasyonudur.
  </p>
  <h2>2. Oyun Açıklaması ve Süresi</h2>
  <p>
    “Bir elektronik bahis oyununun tek bir turunun uzunluğu 3 ila 5 dakika arasındadır. Bu, fikstür duyurusu, başlangıç,
    oyun içi gösterim ve sonucu içerir. Oyun içi gösterim 1 dakika uzunluğunda olacaktır.”
  </p>
  <p>
    Öne çıkan futbol etkinliklerinin bir seçkisi istatistiksel olarak bağımsızdır, rastgeledir, tahmin edilemez ve
    Rastgele Sayı Oluşturucu - RSO tarafından üretilir.
  </p>
  <h2>3. Bahis Piyasaları</h2>
  <h3>3.1 Kesin Gol Sayısı</h3>
  <p>
    Kesin Gol Sayısı Bahislerinin anlamı, tek bir maçta kesin o sayıda golün atılması gerektiğidir. Yani bu bahis
    çeşidi, maçın nihai sonucuna rağmen kesin sayıda golü tahmin etmeniz gerektiği anlamına gelir.
  </p>
  <h3>3.2 Ev Sahibi Takımın Kesin Gol Sayısı</h3>
  <p>Ev sahibi takımın maçta atacağı gollerin kesin sayısına dair bahistir.</p>
  <h3>3.3 Deplasman Takımının Kesin Gol Sayısı</h3>
  <p>Deplasman takımının maçta atacağı gollerin kesin sayısına dair bahistir.</p>
  <h3>3.4 İki Takım da Gol Atar</h3>
  <p>
    İTGA bahsini kazanmak için her iki takımın da en az bir gol atması gerekmekte olup, 1-1 ve üzeri sonuçlar ile bahis
    kazanılır. &quot;İki Takım da Gol Atar&quot; bahsi, iki takımdan yalnızca birinin gol atmayı başardığı durumlarda,
    bahisçilere karlı oranlar elde etmeleri için bu tür etkinliklerden yararlanma fırsatı tanır.
  </p>
  <h3>3.5 Doğru Skor</h3>
  <p>
    Doğru skor bahsi, bir futbol maçının nihai sonucunun ne olacağını düşündüğünüz üzerine bahis oynamak olup, bir maçta
    en fazla 6 gol ve 28 olası sonuç olacaktır.
  </p>
  <h3>3.6 Çifte Şans</h3>
  <p>
    Çifte şans bahsi, bir futbol maçındaki üç olası sonuçtan ikisini tek bir bahisle kapsamanıza imkan tanır. Ev sahibi
    takım ve beraberlik - Ev sahibi takımın maçı kazanması veya berabere kalması durumunda bahsi kazanırsınız. Deplasman
    takımı ve beraberlik - Deplasman takımının maçı kazanması veya berabere kalması durumunda bahsi kazanırsınız.
  </p>
  <h3>3.7 Maç Sonucu (1X2)</h3>
  <p>
    Ev sahibi takımın kazanması (1), deplasman takımının kazanması (2) veya maçın berabere bitmesi (X) üzerine oynanan
    bahistir.
  </p>
  <h3>3.8 Maç Sonucu ve İki Takım da Gol Atar (1X2 + İTGA)</h3>
  <p>
    Maçın sonucuna (1 ev sahibi takımın galibiyeti, X beraberlik, 2 deplasman takımının galibiyeti) ve her iki takımın
    da gol atıp atamayacağına dair bahistir.
  </p>
  <ul>
    <li>1 + Gol - Ev sahibi takım kazanır ve her iki takım da en az bir gol atar [örn. 3-1].</li>
    <li>1 + Gol Yok - Ev sahibi takım kazanır ve gol atan tek takım olur [örn. 1-0].</li>
    <li>X + Gol - Beraberliğe ve her iki takımın skorunun eşit olmasına bahse girin. [örn. 1-1, 2-2].</li>
    <li>X + Gol Yok - Beraberliğe ve iki takımın da gol atamamasına bahse girin. [Sadece 0-0].</li>
    <li>2 + Gol - Deplasman takımı kazanır ve her iki takım da en az bir gol atar [örn. 1-2, 1-3].</li>
    <li>2 + Gol Yok - Deplasman takımı kazanır ve gol atan tek takım olur [örn. 0-1, 0-2].</li>
  </ul>
  <h3>3.9 Maç Sonucu ve 1,5 Gol Üstü/Altı</h3>
  <p>
    1X2 bahsine girilen maçın sonucuna ve toplam gol sayısının 1,5 golün altında veya üstünde olacağına dair bahistir.
  </p>
  <h3>3.10 Maç Sonucu ve 2,5 Gol Üstü/Altı</h3>
  <p>
    1X2 bahsine girilen maçın sonucuna ve toplam gol sayısının 2,5 golün altında veya üstünde olacağına dair bahistir.
  </p>
  <h3>3.11 Toplam Gol Sayısı (1 ila 2, 1 ila 3, 1 ila 4, 2 ila 3, 2 ila 4)</h3>
  <p>
    Maçın nihai sonucunda 1 ila 2, 1 ila 3, 1 ila 4, 2 ila 3 veya 2 ila 4 toplam gol sayısı olacağına dair bahistir. Bu
    bir EVET veya HAYIR bahsidir.
  </p>
  <h3>3.12 1,5 Ev Sahibi Takım Golü Üstü/Altı</h3>
  <p>Ev sahibi takımın maçta 1,5 golün altında veya üstünde gol atacağına dair bahistir.</p>
  <h3>3.13 1,5 Deplasman Takımı Golü Üstü/Altı</h3>
  <p>Deplasman takımının maçta 1,5 golün altında veya üstünde gol atacağına dair bahistir.</p>
  <h3>3.14 1,5, 2,5, 3,5, 4,5 Toplam Gol Sayısı Üstü/Altı</h3>
  <p>
    Maçta her iki takımın attığı toplam gol sayısının 1,5, 2,5, 3,5 veya 4,5&#39;in altında veya üzerinde olacağına dair
    bahistir.
  </p>
</template>
